import { useEffect, useState } from "react";
import "./App.css";
import EventBtn from "./eventBtn";
import letterImg from "./letterImg.png";
import errorIcon from "./errorIcon.png";

function App() {
  const BASEURL = "https://oushna-uzair-rsvp.azurewebsites.net/" || "http://localhost:8080";

  const initialFormInfo = {
    inviteCode: "",
    firstName: "",
    lastName: "",
    family: [],
    phoneNumber: "",
    events: [],
  };
  const [formInfo, setFormInfo] = useState(initialFormInfo);
  const [extraGuests, setExtraGuests] = useState([]);
  const [invitedEvents, setInvitedEvents] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [error, setErrorMsg] = useState(false);

  // to organize guest list
  const inviteCodeMap = {
    F7MPE9: "all",
    GFTB2X: "shaadi",
    JZGN42: "walima",
    H5X3F5: "shaadiAndWalima",
    CJKTQ5: "nikkahAndShaadi",
    AP2KTM: "nikkah",
    E6WCAE: "nikkahAndWalima"
  };

  const eventsInfo = [
    {
      title: "Nikkah",
      locationName: "ICCO Masjid",
      address: "2550 Dunwin Dr, Mississauga, ON L5L 1T1",
      date: "July 6th, 2024",
      time: "3:30 PM",
    },
    {
      title: "Shaadi",
      locationName: "Palacio Event Centre",
      address: "3410 Semenyk Ct, Mississauga, ON L5C 4P8",
      date: "Aug 2nd, 2024",
      time: "6:00 PM",
    },
    {
      title: "Walima",
      locationName: "Candles Banquet Hall",
      address: "1224 Dundas St E, Mississauga, ON L4Y 4A2",
      date: "Aug 4th, 2024",
      time: "6:00 PM",
    },
  ];

  // add a guest to the list
  const addMember = (e) => {
    e.preventDefault();
    let newGuests = [...extraGuests];
    newGuests.push("");
    setExtraGuests(newGuests);

    let updatedFormInfo = { ...formInfo };
    updatedFormInfo["family"] = newGuests;
    setFormInfo(updatedFormInfo);
  };

  // remove the last person that was added
  const subtractMember = (e) => {
    e.preventDefault();
    let newGuests = [...extraGuests];
    newGuests.pop();
    setExtraGuests(newGuests);

    let updatedFormInfo = { ...formInfo };
    updatedFormInfo["family"] = newGuests;
    setFormInfo(updatedFormInfo);
  };

  // update form value for family member's name
  const handleChangeFamily = (familyIndex, newVal) => {
    let currFamilyList = [...extraGuests];
    currFamilyList[familyIndex] = newVal;

    setExtraGuests(currFamilyList);

    let updatedFormInfo = { ...formInfo };
    updatedFormInfo["family"] = currFamilyList;
    setFormInfo(updatedFormInfo);
  };

  // update form value for field
  const handleChange = (field, newVal) => {
    let updatedFormInfo = { ...formInfo };
    updatedFormInfo[field] = newVal;
    setFormInfo(updatedFormInfo);
  };

  // clear everything
  const clearForm = () => {
    setFormInfo(initialFormInfo);
    setExtraGuests([]);
  };

  // add a selected event to the list
  const addEvent = (title) => {
    let updatedFormInfo = { ...formInfo };
    updatedFormInfo.events.push(title);
    setFormInfo(updatedFormInfo);
  };

  // de-select an event from the list
  const removeEvent = (title) => {
    let updatedFormInfo = { ...formInfo };
    let updatedEvents = updatedFormInfo.events.filter(
      (event) => event !== title
    );
    updatedFormInfo.events = updatedEvents;
    setFormInfo(updatedFormInfo);
  };

  /**************** VALIDATION FUNCTIONS *************************/
  const validGuestCode = () => {
    let check = inviteCodeMap[formInfo.inviteCode] ?? null;
    return check ? true : false;
  };

  const validNumber = () => {
    let parseNumber = formInfo.phoneNumber;
    parseNumber = parseNumber.replaceAll("(", "");
    parseNumber = parseNumber.replaceAll(")", "");
    parseNumber = parseNumber.replaceAll("-", "");

    let correctLength = parseNumber.length === 10;
    let allDigits = parseNumber.match(/^[0-9]+$/);

    return correctLength && allDigits;
  };

  const validEvents = () => {
    return formInfo.events.length > 0;
  }

  const validName = () => {
    const validFam = () => {
      for (let member = 0; member < formInfo.family.length; member++) {
        if (formInfo.family[member].length === 0) {
          return false;
        }
      }
      return true;
    };

    return (
      formInfo.firstName.length > 0 &&
      formInfo.lastName.length > 0 &&
      (formInfo.family.length > 0 ? validFam() : true)
    );
  };

  // SUBMISSION FOR BACKEND
  const submit = async () => {
    let formattedData = { ...formInfo };
    let eventList = formInfo.events;
    // main person submitting the form
    let guests = [
      {
        firstName: formInfo.firstName,
        lastName: formInfo.lastName,
        phoneNumber: formInfo.phoneNumber,
      },
    ];

    // add the remaining family members as their own individuals
    // all under the same phone number
    formattedData.family.forEach((member) => {
      guests.push({
        firstName: member.split(" ")[0],
        lastName: member.split(" ")[1] ?? "",
        phoneNumber: formInfo.phoneNumber,
      })
    });

    // add each guest to their respective event
    for (const familyMember of guests) {
      for (const eventTitle of eventList) {
        let PATH = eventTitle.toLowerCase();
        await fetch(`${BASEURL}/${PATH}`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(familyMember),
        });
      }
    }
  };

  const validateSubmission = async (e) => {
    e.preventDefault();

    // validate all required fields
    if (!validEvents()) {
      setErrorMsg("Please select the events you'll be attending");
    }
    if (!validNumber()) {
      setErrorMsg("Invalid phone number");
    }
    if (!validName()) {
      setErrorMsg("Please enter your full name");
    }
    if (!validGuestCode()) {
      setErrorMsg("Invalid invite code");
    }

    // try submission
    if (validGuestCode() && validNumber() && validName() && validEvents()) {
      // clear form
      clearForm();
      // clear errors
      setErrorMsg(false);

      // send submission request and display thank you message
      await submit().then(setSubmitted(true));
    } else {
      setSubmitted(false);
    }
  };

  // UI rendering for events guest was invited to
  useEffect(() => {
    if (inviteCodeMap[formInfo.inviteCode] === "all") {
      setInvitedEvents(eventsInfo);
    } else if (inviteCodeMap[formInfo.inviteCode] === "nikkah") {
      setInvitedEvents(eventsInfo.filter((event) => event.title === "Nikkah"));
    } else if (inviteCodeMap[formInfo.inviteCode] === "shaadi") {
      setInvitedEvents(eventsInfo.filter((event) => event.title === "Shaadi"));
    } else if (inviteCodeMap[formInfo.inviteCode] === "walima") {
      setInvitedEvents(eventsInfo.filter((event) => event.title === "Walima"));
    } else if (inviteCodeMap[formInfo.inviteCode] === "shaadiAndWalima") {
      setInvitedEvents(
        eventsInfo.filter(
          (event) => event.title === "Shaadi" || event.title === "Walima"
        )
      );
    } else if (inviteCodeMap[formInfo.inviteCode] === "nikkahAndShaadi") {
      setInvitedEvents(
        eventsInfo.filter(
          (event) => event.title === "Shaadi" || event.title === "Nikkah"
        )
      );
    } else if (inviteCodeMap[formInfo.inviteCode] === "nikkahAndWalima") {
      setInvitedEvents(
        eventsInfo.filter(
          (event) => event.title === "Walima" || event.title === "Nikkah"
        )
      );
    } else {
      setInvitedEvents([]);
    }
    // eslint-disable-next-line
  }, [formInfo.inviteCode]);

  return (
    <div className="App">
      <div className="appBox">
        <div className="section eventCard">
          <div
            className={`${
              invitedEvents.length > 0 ? "readjustedTop" : "adjustedTop"
            }`}
          >
            We're excited to invite you to the wedding of
          </div>
          <br />
          <div className="coupleName">Oushna <div>&</div> Uzair</div>
          <br />
          <div className="eventGrid">
            {invitedEvents.map((event, column) => {
              return (
                <div
                  className={`eventBox ${
                    column === 1 || column === 2 ? "leftborder" : ""
                  }`}
                >
                  <div className="eventTitle">{event.title}</div>
                  <div className="details">{event.locationName}</div>
                  <div className="details">{event.address}</div>
                  <div className="details">
                    {event.date} at {event.time}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="section rsvpSection">
          {!submitted ? (
            <>
              <div id="rsvpText">RSVP</div>
              <br />
              <div className={`${error ? "errorMsg" : "noError"}`}>
                <div className="errorContent">
                  <img src={errorIcon} alt="error" />
                  <div>ERROR: {error}</div>
                </div>
              </div>
              <form className="rsvpForm">
                <div className="field">
                  <div>6-Digit invite code </div>
                  <input
                    onChange={(e) => handleChange("inviteCode", e.target.value)}
                    value={formInfo.inviteCode}
                    placeholder="You should have received this in your invite"
                    required={true}
                  />
                </div>

                <div className="field">
                  <div>First name </div>
                  <input
                    onChange={(e) => handleChange("firstName", e.target.value)}
                    value={formInfo.firstName}
                    placeholder=""
                    required={true}
                  />
                </div>

                <div className="field">
                  <div>Last name </div>
                  <input
                    onChange={(e) => handleChange("lastName", e.target.value)}
                    value={formInfo.lastName}
                    placeholder=""
                    required={true}
                  />
                </div>

                <div className="field">
                  <span className="multipleFields">
                    <div className="spouseField">Family members</div>
                    <div>
                      <button onClick={addMember} className="addBtn">
                        +
                      </button>
                      <button onClick={subtractMember} className="subtractBtn">
                        -
                      </button>
                    </div>
                  </span>
                  <div className="childrenBlurb">
                    Please only include children over the age of 3
                  </div>
                  {extraGuests.map((member, index) => {
                    return (
                      <input
                        className="addedInputs"
                        onChange={(e) =>
                          handleChangeFamily(index, e.target.value)
                        }
                        placeholder={`${member === "" ? "Full name" : ""}`}
                        value={member}
                        required={true}
                      />
                    );
                  })}
                </div>

                <div className="field">
                  <div>Phone number </div>
                  <input
                    onChange={(e) =>
                      handleChange("phoneNumber", e.target.value)
                    }
                    value={formInfo.phoneNumber}
                    placeholder="(xxx)-xxx-xxxx"
                    required={true}
                  />
                </div>

                {invitedEvents.length > 0 ? (
                  <div className="field">
                    Select all events you will be attending
                  </div>
                ) : (
                  <></>
                )}
                <div className="eventCheckboxGrid">
                  {invitedEvents.map((event, column) => {
                    return (
                      <EventBtn
                        addEventHandler={addEvent}
                        removeEventHandler={removeEvent}
                        title={event.title}
                      />
                    );
                  })}
                </div>

                <div className="guestCount">
                  Seats you're reserving: {formInfo.family.length + 1}
                </div>

                <br />
                <button onClick={validateSubmission} className="rsvpBtn">
                  SUBMIT
                </button>
              </form>
            </>
          ) : (
            <div className="receivedBox">
              <div>
                <img src={letterImg} alt="letter" />
              </div>
              <div className="thankyouText">Thank you!</div>
              <div>Your submission has been received.</div>
              <div>We look forward to seeing you!</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
