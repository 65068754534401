import React from "react";
import { useState } from "react";
import "./App.css";

export default function EventBtn({ addEventHandler, removeEventHandler, title }) {
    const [click, setClicked] = useState(false);

    const handleClick = () => {
        if (click) {
            removeEventHandler(title);
            setClicked(false);
        } else {
            addEventHandler(title);
            setClicked(true);
        }
    }

  return <div onClick={handleClick} className={`eventField ${click ? 'eventClicked' : ''}`}>{title}</div>;
}
